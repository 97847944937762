<template>
	<div>
		<!-- <el-dialog :title="$t('i18nn_f7e99b8c203e2b07')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px"
		top="10px"> -->
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_43f2b49eef6bda66')" append-to-body :visible.sync="dialogFile" :direction="'rtl'"
			size="1200px">
			<div class="">
				<el-tabs v-model="detTabActiveName" type="border-card">
					<el-tab-pane label="" name="first" v-loading="loading">
						<span slot="label"><i class="el-icon-document"></i> <span>{{$t('i18nn_e6a250894a04db4c')}}</span></span>
						<!--本页切换列表-->
						<!-- <div> -->
						<div class="tableCon" v-loading="loading_load" element-loading-text="加载中...">
							<div class="tableConTable">
								<!-- <div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">undefined</el-button>{{$t('i18nn_92f2ebbe14794f38')}}</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">


								<el-button type="success" circle icon="el-icon-refresh" size="small"
									@click="initData()"></el-button>
							</el-col>
						</el-row>
					</div> -->

								<div class="filterCon" style="">
									<div class="filterAction">
										<!-- <ul class="actionConList"> -->
											<!-- <li>
												<el-button type="success" @click="toCreate()" size="small"
													icon="el-icon-plus">{{$t('i18nn_786593149c0bf9f8')}}</el-button>
											</li> -->
											<!-- <li v-if="10==filterData.status">
												<el-button type="danger" @click="commitCancelAction($event, null)" size="small" icon="el-icon-sold-out">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>
												
											</li> -->
										<!-- </ul> -->
										<ul class="filterConList">
											<!-- <li>
												<span>{{ $t('i18nn_29991afa9781d554') }}</span>
												<cusSelFuzzy :clearable="true" @changeData="changCus"></cusSelFuzzy>
											</li> -->
											<!-- <li>
												<span class="">{{$t('i18nn_c944a6686d996ab3')}}</span>
												<whNoSelect @changeData="changWhNo" size="small"></whNoSelect>
											</li> -->
											<li>
												<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
												<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
													maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;">
												</el-input>
											</li>
									
									
									
											<li>
												<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
											</li>
											<!-- <li>
												<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()">
												</el-button>
											</li> -->
										</ul>
									</div>
								</div>

								<!-- <div>
							<el-card shadow="never" style="margin-bottom: 10px;">
								<el-descriptions :title="''">
									<el-descriptions-item :label="$t('i18nn_37181c74559db182')">{{tableData.orderNumber}}</el-descriptions-item>
									
									<el-descriptions-item :label="$t('i18nn_148c3451c7861e8f')">{{tableData.batchNo}}</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_ce764b74be1b9a90')">{{tableData.workNo}}</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_83b1b50eb00a9fb6')">{{tableData.thirdOrderNumber}}</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_18d7892498938435')">{{tableData.cusName}}</el-descriptions-item>
									<el-descriptions-item label="undefined">
										{{ tableData.exprStatusName }}
									</el-descriptions-item>
									<el-descriptions-item label="undefined">{{tableData.commitDate}}</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_d99d790ec4383bfb')">{{tableData.exprStatusTime}}</el-descriptions-item>
								</el-descriptions>
							</el-card> -->

								<!-- <el-card shadow="never" style="margin-bottom: 10px;"> -->
								<!-- <div slot="header" class="">
									<span>{{$t('i18nn_43f2b49eef6bda66')}}</span>
								</div> -->
								<el-table border :data="tableData" size="small" style="width: 100%;"
									:max-height="$store.state.frameConHeightWh1">
									<el-table-column type="index" fixed="left" width="50" align="center"
										:label="$t('Storage.tableColumn.no')"></el-table-column>

									<!-- <el-table-column prop="trRecordId" :label="$t('i18nn_a332a38ed3832d9d')">
									</el-table-column> -->
									<el-table-column prop="statusName" :label="$t('i18nn_515bc077445e40dc')">
										<template slot-scope="scope">
											<div>

												<!-- <el-tag type="info" size="mini">{{item.statusName}}</el-tag> -->
												<el-tag type="success"
													v-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
												<el-tag type=""
													v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
												<el-tag type="warning"
													v-else-if="'30' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
												<el-tag type="danger"
													v-else-if="'40' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
												<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>

											</div>
										</template>
									</el-table-column>

									<el-table-column prop="planNo" :label="$t('i18nn_cc335ba8f4b2cc0d')" width="">
									</el-table-column>

									<el-table-column prop="trWayName" :label="$t('i18nn_e1e2b21c7b4b951f')" width="">
										<template slot-scope="scope">
											<div>
												<div>
													<el-tag type="primary" effect="plain"
														v-if="'1'==scope.row.trWay">{{scope.row.trWayName}}</el-tag>
													<el-tag type="success" effect="plain"
														v-else-if="'2'==scope.row.trWay">{{scope.row.trWayName}}</el-tag>
													<el-tag type="warning" effect="plain"
														v-else-if="'3'==scope.row.trWay">{{scope.row.trWayName}}</el-tag>
													<el-tag type="info" effect="plain" v-else>{{scope.row.trWayName}}</el-tag>
												</div>
												<div v-if="'1'==scope.row.trWay">
													<span>{{$t('i18nn_7f0e36c0c32152b9')}}</span>:
													<span>{{scope.row.trAddrCode}}</span>
												</div>
												<div v-else-if="'3'==scope.row.trWay">
													<span>{{$t('i18nn_3d6d2d670fa63b7c')}}</span>:
													<span>{{scope.row.trAddrCode}}</span>
												</div>
											</div>
										</template>
									</el-table-column>

									<el-table-column prop="addrTypeName" :label="$t('i18nn_0bbce74881c893df')" width="">
									</el-table-column>
									<el-table-column prop="address" :label="$t('i18nn_8758fd50c87d6c9c')" width="">
									</el-table-column>

									<el-table-column prop="isPlanedName" :label="$t('i18nn_b7a786a287eb00e4')" width="">
									</el-table-column>
									<el-table-column prop="isChangeLabelName" :label="$t('i18nn_fa02547543cff0f7')" width="">
									</el-table-column>
									<el-table-column prop="planDate" :label="$t('i18nn_fb6105f4b294a9d0')" width="">
									</el-table-column>
									<el-table-column prop="pallteCount" :label="$t('i18nn_886ea69af099215d')" width="">
									</el-table-column>
									<el-table-column prop="labelCount" :label="$t('i18nn_08e5062e3affb676')" width="">
									</el-table-column>
									<el-table-column prop="BOL" :label="'BOL'" width="">
										<template slot-scope="scope">
											<div @click="openUploadFileBol($event, scope.row)">
												<el-button type="text" size="small" icon="el-icon-paperclip">BOL附件</el-button>
												<div v-if="scope.row.bolFiles && scope.row.bolFiles.length>0">
													<el-link type="primary">
														...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.bolFiles.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span>
													</el-link>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="POD" :label="'POD'" width="">
										<template slot-scope="scope">
											<div @click="openUploadFilePod($event, scope.row)">
												<div v-if="scope.row.podFiles && scope.row.podFiles.length>0">
													<el-link type="primary">
														...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.podFiles.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span>
													</el-link>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="isa" :label="'ISA'" width="">
									</el-table-column>
									<el-table-column prop="pro" :label="'PRO'" width="">
									</el-table-column>
									
									<el-table-column prop="trunckingPrice" :label="$t('i18nn_6867f16a2ac5e825')" width="">
									</el-table-column>
									
									<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
										<template slot-scope="scope">
											<el-popover placement="top" trigger="hover">
												<div class="pre-text">{{ scope.row.remark }}</div>
												<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
											</el-popover>
										</template>
									</el-table-column>
									<el-table-column prop="" :label="$t('i18nn_e6bdd31025bb4f9f')" width="400">
										<template slot-scope="scope">
											<div>
												<!-- <el-collapse accordion> -->
												<!-- <el-collapse-item> -->
												<!-- <template slot="title">
													<div style="padding:0 10px;">
														<span>{{$t('i18nn_e90a876d882d595a')}}</span>
														<i class="header-icon el-icon-arrow-down"></i>
														<el-tag size="mini" v-if="scope.row.planList">
															{{ scope.row.planList.length}}
														</el-tag>
													</div>
										      
										    </template> -->
												<!-- <div> -->
												<table class="simTable" width="100%">
													<tr class="table-header">
														<td>{{$t('i18nn_54f17bfe03d79b8a')}}</td>
														<td>FBA</td>
														<td>PO</td>

														<!-- <td>{{$t('i18nn_42274ccae6a91180')}}</td> -->
														<td>{{$t('i18nn_f9db93b87e08763b')}}</td>
														<td>{{$t('i18nn_96f1a4b17e75892d')}}</td>
														<td>{{$t('i18nn_f340f0ba85ea230d')}}</td>

													</tr>
													<tbody>
														<tr v-for="(item3,index3) in scope.row.planList" :key="index3">
															<td>
																{{item3.ctnMark}}
															</td>
															<td>
																{{item3.fbaNo}}
															</td>
															<td>
																{{item3.fbaPre}}
															</td>

															<!-- <td>
																	{{item3.planNo}}
																</td> -->
															<td>
																{{item3.quantity}}
															</td>
															<td>
																{{item3.totalWeight}}
															</td>
															<td>
																{{item3.totalVol}}
															</td>

														</tr>
													</tbody>
												</table>
												<!-- </div> -->
												<!-- </el-collapse-item> -->
												<!-- </el-collapse> -->
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="" :label="$t('Storage.tableColumn.operation')" width="150px" fixed="right"
										align="left">
										<template slot-scope="scope">
											<div>
												<!-- <div style="margin-bottom: 5px;" v-if="10==scope.row.status">
											<el-button @click="acceptPlanAction($event, scope.row)" type="success" size="mini"
												icon="el-icon-document">undefined</el-button>
										</div>
										
										<div style="margin-bottom: 5px;" v-if="20==scope.row.status">
											<el-button type="primary" size="mini" @click="AppointmentCommitAction($event, scope.row)"
												icon="el-icon-document">{{$t('i18nn_2bcdd4344979655f')}}</el-button>
										</div>
										
										<div style="margin-bottom: 5px;" v-if="scope.row.status==30">
											<el-button type="warning" size="mini" @click="planPickUpAction($event, scope.row)"
												icon="el-icon-document">{{$t('i18nn_68fd8440c5a9dd35')}}</el-button>
										</div>
										
										<div style="margin-bottom: 5px;">
											<el-button type="danger" size="mini" @click="editPlanAction($event, scope.row)"
												icon="el-icon-edit">undefined</el-button>
										</div> -->
												<div style="margin-bottom: 5px;">
													<el-button @click="printBolAction($event, scope.row)" type="primary" size="mini"
														icon="el-icon-printer">BOL<span>{{$t('i18nn_da1ca7369f4bde15')}}</span></el-button>
												</div>
											</div>

										</template>
									</el-table-column>





								</el-table>
								<!-- </el-card> -->


								<!-- <el-card shadow="never" style="">
								<div slot="header" class="">
									<span>undefined</span>
								</div>
								<ul>
									<li v-for="(item2,index2) in row.attachments" :key="index2">
										<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999" :fit="'contain'"
											:src="item2.url" :preview-src-list="[item2.url]">
											<div slot="error" class="image-slot">
												<i class="el-icon-document"></i>
											</div>
										</el-image>
										<a :href="item2.url" :title="item2.url" target="_blank">{{ item2.fileName }}</a>
								
									</li>
								</ul>
							</el-card> -->
							</div>


						</div>
						<!-- <div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div> -->
						<!-- </div> -->
						<!-- </div> -->

					</el-tab-pane>
					<el-tab-pane label="" name="second">
						<span slot="label"><i class="el-icon-coin"></i> <span>{{$t('i18nn_d7315fb8114eb446')}}</span></span>

						<FeeModelById :id="row.id"></FeeModelById>
					</el-tab-pane>
				</el-tabs>
			</div>
			<!-- <div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialogFile = false">{{ $t('i18nn_4e9fc68608c60999') }}</el-button>
		</div> -->
		</el-drawer>
		<!-- </el-dialog> -->
		<!-- 预约修改 -->
		<!-- <TransportFastPlanEdit :row="PlanEditRow" :openTime="PlanEditOpenTime" @success="editPlanSuccess">
		</TransportFastPlanEdit> -->

		<!-- 预约完成 -->
		<!-- <TransportPlanOutWhComplate :row="AppointmentEditRow" :openTime="AppointmentEditOpenTime"
			@success="AppointmentComplateSuccess"></TransportPlanOutWhComplate> -->

		<!-- 拆柜完成 -->
		<!-- <TransportFastComplate :row="EevanningEditRow" :openTime="EevanningEditOpenTime"
		@success="EevanningComplateSuccess"></TransportFastComplate> -->

		<!-- 提货完成 -->
		<!-- <TransportFastPickUp :row="PickUpEditRow" :openTime="PickUpEditOpenTime" @success="PickUpComplateSuccess">
		</TransportFastPickUp> -->


		<!--  附件查看-pod -->
		<!-- <whFileView2 :openTime="PodFileViewOpenTime" :fileList="PodFileDataList"></whFileView2> -->
		<!-- <whFileUploadByType :openTime="PodFileViewOpenTime" :relationId="PodFileRelationId" :title="'POD'" :typeCode="'30'"
			:userId="PodFileUploadUserId" @success="PodFileUploadSuccess">
		</whFileUploadByType> -->

		<!--  附件上传-bol -->
		<!-- <whFileUploadByType :openTime="BolFileUploadOpenTime" :relationId="BolFileRelationId" :title="'BOL'"
			:typeCode="'20'" :userId="BolFileUploadUserId" @success="BolFileUploadSuccess">
		</whFileUploadByType> -->

		<!--  附件查看-pod -->
		<whFileView2 :openTime="PodFileViewOpenTime" :fileList="PodFileDataList"></whFileView2>

		<!--  附件上传-bol -->
		<whFileUploadByType :openTime="BolFileUploadOpenTime" :relationId="BolFileRelationId" :title="'BOL'"
			:typeCode="'20'" :userId="BolFileUploadUserId" @success="BolFileUploadSuccess">
		</whFileUploadByType>

	</div>
</template>
<script>
	import whFileUploadByType from '@/components/StorageCenter/components/whFileUploadByType.vue';
	import whFileView2 from '@/components/StorageCenter/components/whFileView2.vue';
	// import TransportFastComplate from '@/components/WarehouseCenter2/Transport/TransportFastComplate.vue';
	import FeeModelById from '@/components/StorageCenter/Other/FeeModelById.vue';
	// import TransportPlanOutWhComplate from '@/components/StorageCenter/Transport/TransportPlanOutWhComplate.vue';
	// import TransportFastPlanEdit from '@/components/StorageCenter/Transport/TransportFastPlanEdit.vue';

	// import TransportFastPickUp from '@/components/StorageCenter/Transport/TransportFastPickUp.vue';

	export default {
		// name: 'HomeConfFrame',
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}
		},
		components: {
			whFileView2,
			whFileUploadByType,
			FeeModelById
			// TransportPlanOutWhComplate,
			// TransportFastPlanEdit,
			// TransportFastPickUp
		},
		data() {
			return {
				detTabActiveName: 'first',

				dialogFile: false,
				// multipleSelection: [],
				// expands:[],

				loading_load: false,

				// fileRelationId: '',
				// FileUploadOpenTime: '',
				// FileUploadUserId: "",

				PodFileViewOpenTime: "",
				// PodFileRelationId: "",
				// PodFileUploadUserId: "",
				PodFileDataList: [],

				BolFileUploadOpenTime: "",
				BolFileRelationId: "",
				BolFileUploadUserId: "",

				// ReceiptFileUploadOpenTime: "",
				// ReceiptFileRelationId: "",
				// ReceiptFileUploadUserId: "",


				// drawerFilter: false,

				//拆柜完成
				// EevanningEditRow: '',
				// EevanningEditOpenTime: '',

				//预约完成
				// AppointmentEditRow: '',
				// AppointmentEditOpenTime: '',

				//预约修改
				// PlanEditRow: '',
				// PlanEditOpenTime: '',

				//提货完成
				// PickUpEditRow: '',
				// PickUpEditOpenTime: '',

				// loading_det_load: false,

				// loading_count: false,
				// countData: {},

				//表格数据
				//loading,表格数据
				loading: false,
				//表格数据
				tableData: [],
				//分页数据
				// pagination: this.$Utils.defaultPagination(),

				// selectOption: {
				// 	wh_no: [],
				// },
				//查询，排序方式
				filterData: {
					// orderBy: 'id_', //排序字段
					// sortAsc: 'desc', //desc降序，asc升序

					// workNo: '',
					// exprStatus: '',
					// orderNumber: '',
					// orderNumberList: [],
					// orderNumberListStr: '',
					// // cusNo: '',
					// userId: '',
					// commitDate: '',
					// commitDateArr: [],
					// isLabelUrl: 1,
					// carrier: '',
					keyword: '',
					// senderAddr: '',
					// returnAddr: '',
					// batchNo: ''
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				//初始化
				this.dialogFile = true;
				this.initData();
				
			}
		},

		activated() {
			// console.log('activated');
		},
		//创建时
		created() {
			// console.log('created');
		},
		//编译挂载前
		mounted() {

		},
		methods: {
			goBack() {
				// console.log('window.history.length',window.history.length);
				// if (window.history.length > 2) {
				// 	this.$router.go(-1);
				// } else {
				// 	this.$router.push({
				// 		name: 'WhDropShippingDashboard'
				// 	});
				// }
				// this.dialogFile = false;
				this.$emit('success', '')
			},

			initData() {
				// this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.tableData = [];
				if(this.row.id){
					this.getPageData(this.row.id)
				}
			},
			//分页的筛选项数据
			// pageFilterData() {
			// 	// let commitDateArr = this.filterData.commitDateArr;
			// 	// let startCommitDate = '';
			// 	// let endCommitDate = '';
			// 	// if (commitDateArr && commitDateArr.length == 2) {
			// 	// 	startCommitDate = commitDateArr[0];
			// 	// 	endCommitDate = commitDateArr[1];
			// 	// }
			// 	// let orderNumberList = [];

			// 	// if (this.filterData.orderNumberListStr) {
			// 	// 	orderNumberList = this.filterData.orderNumberList;
			// 	// } else if (this.filterData.orderNumber) {
			// 	// 	orderNumberList = [this.filterData.orderNumber];
			// 	// }

			// 	return {
			// 		// workNo: this.filterData.workNo ? this.filterData.workNo : null,
			// 		// batchNo: this.filterData.batchNo ? this.filterData.batchNo : null,
			// 		// exprStatus: this.filterData.exprStatus ? this.filterData.exprStatus : null,
			// 		orderNumber: this.filterData.orderNumber ? this.filterData.orderNumber : null,
			// 		// orderNumberList: orderNumberList.length > 0 ? orderNumberList : null,
			// 		// // cusNo: this.filterData.cusNo ? this.filterData.cusNo : null,
			// 		// userId: this.filterData.userId ? this.filterData.userId : null,
			// 		// // commitDate: this.filterData.commitDate ? this.filterData.commitDate : null,

			// 		// startCommitDate: startCommitDate ? startCommitDate : null,
			// 		// endCommitDate: endCommitDate ? endCommitDate : null,

			// 		// keyword: this.filterData.keyword ? this.filterData.keyword : null,
			// 		// carrier: this.filterData.carrier ? this.filterData.carrier : null,
			// 		// isLabelUrl: this.filterData.isLabelUrl ? this.filterData.isLabelUrl : null,

			// 		// senderAddr: this.filterData.senderAddr ? this.filterData.senderAddr : null,
			// 		// returnAddr: this.filterData.returnAddr ? this.filterData.returnAddr : null
			// 		// // goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
			// 	};
			// },

			// //请求分页数据
			getPageData(id) {
				// let _this = this;

				let filterData = {
					trSourceId: id,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
				};

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhTransferFastPlanList, filterData)
					.then(({
						data
					}) => {
						
						this.loading_load = false;
						if (200 == data.code && data.rows) {
							//表格显示数据
							this.tableData = data.rows;
							//当前数据总条数
							// this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('商家账单列表，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error('列表数据，请求失败！');
						this.loading_load = false;
					});
			},

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},

			//BOL附件查看
			openUploadFileBol(event, row) {
				event.stopPropagation();
				// this.BolFileDataList = row.bolFiles;
				this.BolFileUploadUserId = row.userId;
				this.BolFileRelationId = row.id;
				this.BolFileUploadOpenTime = new Date().getTime();
			},
			BolFileUploadSuccess(data) {
				// this.dialogFile = false;
				this.initData();
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = row.id;
				// this.FileUploadOpenTime = new Date().getTime();
			},

			//POD附件
			openUploadFilePod(event, row) {
				event.stopPropagation();
				this.PodFileDataList = row.podFiles;
				this.PodFileViewOpenTime = new Date().getTime();
			},

			//预约受理
			// acceptPlanAction(event, row) {
			// 	event.stopPropagation();
			// 	this.$confirm(this.$t('i18nn_1da6b8c5aa8e9f2e') + '?', this.$t('tips.tipsTitle'), {
			// 		type: 'warning'
			// 	}).then(() => {
			// 		this.postData(this.$urlConfig.WhTransferPlanAccept, {
			// 			"ids": [row.id]
			// 		}, '');
			// 	}).catch(() => {

			// 	});

			// },

			//预约完成
			// AppointmentCommitAction(event, row) {
			// 	event.stopPropagation();
			// 	this.AppointmentEditOpenTime = new Date().getTime();
			// 	this.AppointmentEditRow = row;
			// 	// this.PalletComplateType = 1;
			// },
			// //预约完成，回调
			// AppointmentComplateSuccess() {
			// 	// this.dialogFile = false;
			// 	this.goBack();
			// },

			//提货完成
			// planPickUpAction(event, row) {
			// 	event.stopPropagation();
			// 	// this.$confirm('确定要提货完成吗?', this.$t('tips.tipsTitle'), {
			// 	//   type: 'warning'
			// 	// }).then(() => {
			// 	//   this.postData(this.$urlConfig.WhTransferPlanPackup, {"id":row.id}, '');
			// 	// }).catch(() => {

			// 	// });
			// 	this.PickUpEditOpenTime = new Date().getTime();
			// 	this.PickUpEditRow = row;

			// },
			// //提货完成-回调
			// PickUpComplateSuccess() {
			// 	// this.dialogFile = false;
			// 	this.goBack();
			// },

			//修改预约
			// editPlanAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('row', row);
			// 	this.PlanEditOpenTime = new Date().getTime();
			// 	this.PlanEditRow = row;
			// },
			// //修改预约，回调
			// editPlanSuccess() {
			// 	// this.dialogFile = false;
			// 	this.goBack();
			// },

			//BOL打印
			printBolAction(event, row) {
				event.stopPropagation();
				let ids = [];
				if (row) {
					ids = [row.id];
				} else {
					if (this.multipleSelection.length <= 0) {
						this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
						return;
					}
					ids = this.multipleSelection.map(item => item.id).join(',');
					// this.$router.push({name:"whPriterTransportPlanOutList",ids:ids});
				}

				let routeData = this.$router.resolve({
					name: 'whPriterTransportPlanOutList',
					query: {
						ids: ids
					}
				});
				window.open(routeData.href, '_blank');

			},

			//提交信息
			postData(url, formData, type) {
				// HttpTypelet _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFile = false;
						this.initData();
						if ("delete" == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								type: 'success'
							});
						}
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('204ffab8a6e01870'),
					});
				});
			},

			//提交数据
			// postData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.put(url, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback(data);
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_a7d2e953195a5588'));
			// 			this.loading = false;
			// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_carrier_code', 'wh_signature_service'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				this.selectOption.wh_carrier_code = data.data['wh_carrier_code'];
			// 				this.selectOption.wh_signature_service = data.data['wh_signature_service'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// },

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>