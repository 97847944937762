<template>
	<div class="mainTem">
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_21d58958f280d153')}}</span>
						<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList"> -->
						<!-- <li> -->
							<el-button type="success" @click="toCreate()" size="small"
								icon="el-icon-plus">{{$t('i18nn_786593149c0bf9f8')}}</el-button>
						<!-- </li> -->
					<!-- </ul> -->
					
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<div class="filterBarList">
				<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						<span>
							{{i18nFormatter(item.value)}}
						</span>
					</el-menu-item>
				</el-menu>
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="success" @click="toCreate()" size="small"
							icon="el-icon-plus">{{$t('i18nn_786593149c0bf9f8')}}</el-button>
					</li>
				</ul> -->
				<ul class="filterConList">
					<!-- <li>
						<span class="">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="small"></SelAgentUser>
					</li> -->
					<li>
						<span class="">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="small"></SelAgentUser>
						<!-- <span>{{ $t('i18nn_29991afa9781d554') }}</span> -->
						<!-- <cusSelFuzzy :clearable="true" @changeData="changCus"></cusSelFuzzy> -->
					</li>
					<li>
						<span class="">{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo" size="small"></whNoSelect>
					</li>
					<!-- <li>
						<span class="filterName">{{$t('i18nn_43a3586339251494')}}</span>
						<el-date-picker size="small" v-model="filterData.commitDateArr" type="daterange" align="right"  unlink-panels
							range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd"
							format="yyyy-MM-dd" @change="initData()" :picker-options="pickerOptions">
						</el-date-picker>
					</li> -->
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;">
						</el-input>
					</li>



					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()">
						</el-button>
					</li> -->
				</ul>
			</div>
		</div>
		<!--更多筛选-->
		<!-- <el-drawer :wrapperClosable="false" custom-class="drawerFilter" :title="$t('i18nn_c4ca802cf2230b0b')" :visible.sync="drawerFilter" :direction="'rtl'">
			<div class="drawerFilterCon">
				<ul class="drawerFilterList">
					<li>
						<span class="filterName">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="medium"></SelAgentUser>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo" size="medium"></whNoSelect>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_43a3586339251494')}}</span>
						<el-date-picker v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
							range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd"
							format="yyyy-MM-dd" size="medium" @change="initData()" :picker-options="pickerOptions">
						</el-date-picker>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_66f0e38b9285894b')}}</span>
						<el-radio-group v-model="filterData.wh_car_tms_type" size="medium" @change="initData()">
							<el-radio-button border :label="''">{{$t('i18nn_16853bda54120bf1')}}</el-radio-button>
							<el-radio-button border v-for="item in selectOption.wh_car_tms_type" :key="item.code"
								:label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio-button>
						</el-radio-group>
					</li>
					<li class="filterBtnCon">
						<el-button icon="el-icon-search" size="medium" type="primary" @click="queryMoreFilter()">{{$t('i18nn_1e7246dd6ccc5539')}}</el-button>
					</li>
				</ul>
			</div>
		</el-drawer> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">

			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh1" @selection-change="handleSelectionChange"
				@row-click="handleCurrentChange">

				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->

				<el-table-column prop="sysNo" :label="$t('i18nn_559fed47b0e95407')">
					<template slot-scope="scope">
						<div>
							<!-- <div>
								<el-link type="primary" @click="openPlanDet($event, scope.row)">
									<span>{{scope.row.sysNo}}</span>
								</el-link>
							</div> -->
							<div class="link-copy">
								<span class="link-text" @click="openPlanDet($event, scope.row, scope.$index)">
									{{ scope.row.sysNo }}
								</span>
								<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')" placement="top">
								  <i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.sysNo)"></i>
								</el-tooltip>
							</div>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="packingNo" :label="$t('i18nn_3d3bc466f13154d4')+'/'+$t('i18nn_a31d32ac4015d196')" width="150">

				</el-table-column>

				<el-table-column prop="whNo" :label="$t('i18nn_8af1fd820046d3c1')"></el-table-column>
				<el-table-column prop="packTypeName" :label="$t('i18nn_611f87335433e0c3')">
					<template slot-scope="scope">
						{{ $Utils.i18nKeyText(scope.row,'packTypeName') }}
					</template>
				</el-table-column>

				<el-table-column prop="pallteCount" :label="$t('i18nn_18c1c1a3ea76e9d3')">

				</el-table-column>

				<el-table-column prop="plandtoList" :label="$t('i18nn_d256e16919f5fceb')">
					<template slot-scope="scope">
						<div @click="openPlanDet($event, scope.row)">
							<div v-if="scope.row.plandtoList">
								<el-link type="primary">
									<strong>{{scope.row.plandtoList.length}}</strong>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="220">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_0ae081c3b8c4d4a1')}}</span>:{{scope.row.commitDate}}
								</li>
								<li>
									<span>{{$t('i18nn_9be246e60da2a0a2')}}</span>:{{scope.row.planArrivalDate}}
								</li>
								<li>
									<span>{{$t('i18nn_e69b8beb7706725b')}}</span>:{{scope.row.receiveDate}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="attachments" :label="$t('Storage.packing_list.attachment')">
					<template slot-scope="scope">
						<div @click="openUploadFile($event, scope.row)">
							<div v-if="scope.row.attachments">
								<el-link type="primary">
									...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.attachments.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>

				<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success"
							v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning"
							v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger" effect="plain"
							v-else-if="'28' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type=""
							v-else-if="'30' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger"
							v-else-if="'35' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="plandtoList" label="undefined" min-width="1600">
					<template slot-scope="scope">
						<div>

							<table class="simTable" width="100%">
								<tr class="table-header">
									<td min-width="100">undefined</td>
									<td>{{$t('i18nn_42274ccae6a91180')}}</td>
									<td>{{$t('i18nn_0bbce74881c893df')}}</td>
									<td>{{$t('i18nn_61c33362d88eb0ba')}}</td>
									<td>{{$t('i18nn_ea881f28aae703af')}}</td>
									<td min-width="100">{{$t('i18nn_b7a786a287eb00e4')}}</td>
									<td min-width="100">{{$t('i18nn_fb6105f4b294a9d0')}}</td>
									<td>{{$t('i18nn_c8773d8d74202801')}}</td>
									<td>BOL</td>
									<td>POD</td>
									<td>ISA</td>
									<td>PRO</td>
									<td></td>
								</tr>
								<tbody>
									<tr v-for="(item,index) in scope.row.plandtoList" :key="index">
										<td>
											<el-tag>{{item.statusName}}</el-tag>
										</td>
										<td>
											{{item.planNo}}
										</td>
										<td>
											<el-tag type="info">{{item.addrTypeName}}</el-tag>
											<div>
												{{item.address}}
											</div>
										</td>
										<td>
											{{item.sourceTypeName}}
										</td>
										<td>
											{{item.trWayName}}
										</td>
										<td>
											{{item.isPlanedName}}
										</td>
										<td>
											{{item.planDate}}
										</td>
										<td>
											{{item.pallteCount}}
										</td>
										<td>
											<div @click="openUploadFileBol($event, item)">
												<el-button type="text" size="small" icon="el-icon-paperclip">BOL附件</el-button>
												<div v-if="item.bolFiles && item.bolFiles.length>0">
													<el-link type="primary">
														...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{item.bolFiles.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span>
													</el-link>
												</div>
											</div>
										</td>
										<td>
											<div @click="openUploadFilePod($event, item)">
												<div v-if="item.podFiles && item.podFiles.length>0">
													<el-link type="primary">
														...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{item.podFiles.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span>
													</el-link>
												</div>
											</div>
										</td>
										<td>{{item.isa}}</td>
										<td>{{item.pro}}</td>
										

										<td style="padding:0;">
											<table class="simTable" width="100%">
												<tr class="table-header">
													<td>{{$t('i18nn_54f17bfe03d79b8a')}}</td>
													<td>FBA</td>
													<td>PO</td>
													<td>{{$t('i18nn_42274ccae6a91180')}}</td>
													<td>undefined</td>
													<td>undefined</td>
													<td>{{$t('i18nn_f340f0ba85ea230d')}}</td>
												</tr>
												<tbody>
													<tr v-for="(item2,index2) in item.planList" :key="index2">
														<td>
															{{item2.ctnMark}}
														</td>
														<td>
															{{item2.fbaNo}}
														</td>
														<td>
															{{item2.fbaPre}}
														</td>
														
														<td>
															{{item2.planNo}}
														</td>
														<td>
															{{item2.quantity}}
														</td>
														<td>
															{{item2.totalWeight}}
														</td>
														<td>
															{{item2.totalVol}}
														</td>
														
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
							</table>

							
						</div>
					</template>
				</el-table-column> -->

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="280px" fixed="right" align="left">
					<template slot-scope="scope">
						<div>
							<!-- <div style="margin-bottom: 5px;">
								
								<el-button @click="openPlanDet($event, scope.row)" type="primary" size="mini"
									icon="el-icon-document">{{$t('i18nn_43f2b49eef6bda66')}}</el-button>
							</div> -->

							<div style="margin-bottom: 5px;">

								<el-button @click="openUploadFile($event, scope.row)" type="success" size="mini"
									icon="el-icon-upload2">{{$t('FormMsg.upload_attachment')}}</el-button>
								<!-- </div> -->

								<!-- <div style="margin-bottom: 5px;"> -->

								<el-button @click="openReceiptList($event, scope.row)" type="primary" size="mini"
									icon="el-icon-paperclip">{{$t('i18nn_a7e730af52ab52b1')}}</el-button>
							</div>



							<div style="margin-bottom: 5px;" v-if="'10'==scope.row.status">

								<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
								<!-- </div> -->
								<!-- <div style="margin-bottom: 5px;" v-if="'10'==scope.row.status"> -->

								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button>
							</div>



						</div>

					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!-- 自提单,修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false" top="0" :title="editId?$t('i18nn_ed74196efa90cded'):$t('i18nn_004bc5eedbcc75e0')
			:visible.sync="dialogFormVisible" custom-class="myFullDialog4">
			<div>
				<TransportManageEdit :editId="editId" :openTime="openTimeEditId" @submitSuccess="editSuccess">
				</TransportManageEdit>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{$t('FormMsg.Close')}}</el-button>
			</div>
		</el-dialog> -->
		<!-- 售后 -->
		<!-- <QAModelRecList
			:openTime="QAMoRecListOpenTime"
			:type="'30'"
			:relationId="QAMoRecListRelationId"
			:relationNo="QAMoRecListRelationNo"
			:whNo="QAMoRecListWhNo"
			@success="QAMoRecListSuccess"
		></QAModelRecList> -->
		<!--查看明细-->
		<!-- <el-dialog :title="$t('FormMsg.details_View')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogShowDet" width="1200px" top="0">
			<div style="">
				<TransportManageDetList :sendId="sendId"></TransportManageDetList>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogShowDet = false">{{$t('FormMsg.Close')}}</el-button>
			</div>
		</el-dialog> -->
		<!-- 预约明细 -->
		<TransportFastListPlanDet :openTime="openTimeDsDet" :row="DsDetRow" @success="FastListPlanDetSuccess">
		</TransportFastListPlanDet>


		<!--  附件查看-bol -->
		<!-- <whFileView2 :openTime="PodFileViewOpenTime" :fileList="PodFileDataList"></whFileView2> -->

		<!-- 收货清单 -->
		<whFileView :openTime="openTimeReceiptList" :row="selReceiptListData" :title="$t('i18nn_a7e730af52ab52b1')"
			:typeCode="'60'"></whFileView>


		<!--  附件上传-bol -->
		<!-- <whFileUploadByType :openTime="BolFileUploadOpenTime" :relationId="BolFileRelationId" :title="'BOL'" :typeCode="'20'" @success="BolFileUploadSuccess">
		</whFileUploadByType> -->

		<!--  附件上传 -->
		<whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess">
		</whFileUpload>

		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="{}"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhTransferFastPageList"
			:expHttpFilter="pageFilterData()" :noShowExpDefExcel="true" :hasFormatter="true" :formatterData="formatterData">
		</whExcelCustom>

	</div>
</template>
<script>
	// import {
	// 	getDicData
	// } from '@/axios/common.js';
	
	import {
		TransportFast_status
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';

	// import TransportManageEdit from '@/components/StorageCenter/Transport/TransportManageEdit.vue';

	// import TransportManageDetList from '@/components/StorageCenter/Transport/TransportManageDetList.vue';
	import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';

	// import whFileUploadByType from '@/components/StorageCenter/components/whFileUploadByType.vue';

	import whFileView from '@/components/StorageCenter/components/whFileView.vue';
	// import whFileView2 from '@/components/StorageCenter/components/whFileView2.vue';

	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	// import QAModelRecList from '@/components/StorageCenter/QA/QAModelRecList.vue';
	import TransportFastListPlanDet from '@/components/StorageCenter/Transport/TransportFastListPlanDet.vue';

	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';

	export default {
		// props: {
		//   // mobile:"",
		//   isSel: {
		//     default: function() {
		//       return false;
		//     },
		//     type: Boolean
		//   },
		//   status: {
		//     default: function() {
		//       return '';
		//     },
		//     type: String
		//   }
		// },
		components: {
			whFileUpload,
			// whFileUploadByType,
			SelAgentUser,
			whNoSelect,
			whFileView,
			TransportFastListPlanDet,
			whExcelCustom
			// whFileView2,
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},

				dialogShowDet: false,
				sendId: null,

				fileRelationId: '',
				FileUploadOpenTime: '',

				//收货清单
				openTimeReceiptList: '',
				selReceiptListData: {},

				// PodFileViewOpenTime:"",
				// PodFileDataList:[],

				// BolFileUploadOpenTime:"",
				// BolFileRelationId:"",

				drawerFilter: false,

				//预约明细
				openTimeDsDet: '',
				DsDetRow: {},

				//售后
				// QAMoRecListOpenTime:"",
				// QAMoRecListRelationId:"",
				// QAMoRecListRelationNo:"",
				// // QAMoRecListUserId:"",
				// QAMoRecListWhNo:"",

				pickerOptions: this.$PickerDate.pickerOptions_1(),


				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// editId: "",
				// openTimeEditId: '',
				multipleSelection: [],

				loading: false,

				loading_det: false,

				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					wh_car_tms_type: [],
					// wh_no:[],
					statusList: TransportFast_status
				},
				cusUserId: "",
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// "accountPeriod":"",
					// "billNo":""
					agentUser: "",
					workNo: '',
					status: '',
					packingNo: '',
					keyword: "",
					commitDateArr: [],
					whNo: '',
					wh_car_tms_type: ''
					// putWhNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// declareNameCh: ''
				}
			};
		},
		// watch:{
		// 	'$route.query': function(newVal, oldVal) {
		//     console.log('watchKey');
		//     // if (newVal) {
		//     // console.log('watch openDateTime HyUpLoadfile.vue');
		// 		if(this.$route.query.workNo){
		// 			this.filterData.workNo = this.$route.query.workNo;
		// 			this.initData();
		// 		}

		//     // }
		//   }
		// },
		// activated() {
		// 	if (this.$route.query.state) {
		// 		this.filterData.status = this.$route.query.state;
		// 		// this.initData();
		// 	}
		// 	this.initData();
		// },
		//创建时
		created() {
			if (this.$route.query.state) {
				this.filterData.status = this.$route.query.state;
				// this.initData();
			}

			if (this.$route.query && this.$route.query.agentUser) {
				this.filterData.agentUser = this.$route.query.agentUser;
				this.$nextTick(() => {
					this.$refs.SelAgentUser.initData(this.filterData.agentUser);
				});
			}
			if (this.$route.query && this.$route.query.code) {
				this.filterData.keyword = this.$route.query.code;
			}
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			// getDicData(['wh_car_tms_type'],
			// 	(data)=>{
			// 		this.selectOption.wh_car_tms_type = data['wh_car_tms_type'];
			// });
			//数据字典
			// getDicData(['wh_car_tms_type'],
			// 	(data) => {
			// 		this.selectOption.wh_car_tms_type = data['wh_car_tms_type'];
			// 	});

			this.initData();
		},
		methods: {
			initData() {


				// if(this.cusUserId){
				this.pagination.current_page = 1;
				// this.currentSel = {};
				this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
				// } else {
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// }

			},
			//打开更多筛选
			openMoreFilter() {
				this.drawerFilter = true;
			},
			//查询更多筛选
			queryMoreFilter() {
				this.initData();
				this.drawerFilter = false;
			},
			i18nFormatter(value) {
				return i18nStatus(value, this.selectOption.statusList)
			},

			changCus(data) {
				console.log('changCus', data);
				this.cusUserId = data.userId;
				// this.cusUserName = data.companyName;
				this.initData();
			},

			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},

			//导出 excel
			exportExcel() {
				let columns = [
					// {
					// 	title: this.$t('i18nn_29991afa9781d554'),
					// 	key: 'cusName'
					// },
					{
						title: this.$t('i18nn_c617dfa4c0b5ecb4'),
						key: 'sysNo'
					},
					{
						title: '柜号/跟踪号/货物单号',
						key: 'packingNo'
					},
					{
						title: this.$t('Storage.packing_list.status'),
						key: 'statusName'
					},

					{
						title: this.$t('i18nn_8af1fd820046d3c1'),
						key: 'whNo'
					},

					{
						title: this.$t('i18nn_611f87335433e0c3'),
						key: 'packTypeName'
					},
					{
						title: this.$t('i18nn_18c1c1a3ea76e9d3'),
						key: 'pallteCount'
					},


					{
						title: this.$t('i18nn_0ae081c3b8c4d4a1'),
						key: 'commitDate'
					},
					{
						title: this.$t('i18nn_9be246e60da2a0a2'),
						key: 'planArrivalDate'
					},
					{
						title: this.$t('i18nn_e69b8beb7706725b'),
						key: 'receiveDate'
					},
					{
						title: this.$t('Storage.tableColumn.remark'),
						key: 'remark'
					},
					//-----明细--二层
					{
						title: this.$t('i18nn_cc335ba8f4b2cc0d'),
						key: 'planNo'
					},
					{
						title: this.$t('i18nn_e1e2b21c7b4b951f'),
						key: 'trWayName'
					},
					{
						title: this.$t('i18nn_0bbce74881c893df'),
						key: 'addrTypeName'
					},
					{
						title: this.$t('i18nn_8758fd50c87d6c9c'),
						key: 'address'
					},
					{
						title: this.$t('i18nn_b7a786a287eb00e4'),
						key: 'isPlanedName'
					},
					{
						title: this.$t('i18nn_fa02547543cff0f7'),
						key: 'isChangeLabelName'
					},
					{
						title: this.$t('i18nn_fb6105f4b294a9d0'),
						key: 'planDate'
					},
					{
						title: this.$t('i18nn_886ea69af099215d'),
						key: 'pallteCountDet'
					},
					{
						title: this.$t('i18nn_08e5062e3affb676'),
						key: 'labelCount'
					},
					{
						title: 'ISA',
						key: 'isa'
					},
					{
						title: 'PRO',
						key: 'pro'
					},
					{
						title: this.$t('i18nn_6867f16a2ac5e825'),
						key: 'trunckingPrice'
					},
					//明细--三层 箱标 FBA PO 数量 重量 体积 
					{
						title: this.$t('i18nn_54f17bfe03d79b8a'),
						key: 'ctnMark'
					},
					{
						title: 'FBA',
						key: 'fbaNo'
					},
					{
						title: 'PO',
						key: 'fbaPre'
					},
					{
						title: this.$t('i18nn_f9db93b87e08763b'),
						key: 'quantity'
					},
					{
						title: this.$t('i18nn_96f1a4b17e75892d'),
						key: 'totalWeight'
					},
					{
						title: this.$t('i18nn_f340f0ba85ea230d'),
						key: 'totalVol'
					}
				];
				let Data = [];


				// if (!this.filterData.userId) {
				// 	this.$message.warning(this.$t('i18nn_5184f7a4033b4969'));
				// 	return;
				// }

				this.expExcelData = Data;
				this.excelHead = columns;

				this.excelName = 'TransportFastList';

				this.excelOption = {
					height: 20,
					hasShowMerge: true,
					mergeLengthKey: 'plandtoList',
					mergeDataCol: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'],
					mergeLengthKey2: 'planList',
					mergeDataCol2: ['L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W'],
					// mergeDataRow:[1,2],
				};
				this.excelOpenTime = new Date().getTime();

				// excelUtilsNew.exportExcel(columns, Data, 'WhDropShipping', { height: 20 });
			},

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},

			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			toCreate() {
				// this.$router.push({
				// 	name: "createWorkOrder",
				// 	query: {
				// 		orderType: '30'
				// 	}
				// })
				this.openDioalog();
			},
			//打开新增编辑，弹窗
			openDioalog() {
				//   // console.log(formParm);
				// this.dialogFormVisible = true;
				// this.editId = null;
				// this.openTimeEditId = new Date().getTime();

				this.$router.push({
					name: "TransportFastEdit"
				});
			},

			// //打开编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				// this.openDioalog(row);

				// this.dialogFormVisible = true;
				// this.editId = row.id;
				// this.openTimeEditId = new Date().getTime();
				// this.getPageDetData(row.id);
				this.$router.push({
					name: "TransportFastEdit",
					query: {
						editId: row.id,
						single: true,
					}
				})
			},

			//查看预约数据
			openPlanDet(event, row) {
				event.stopPropagation();

				this.openTimeDsDet = new Date().getTime();
				this.DsDetRow = row;

			},
			FastListPlanDetSuccess(data) {
				this.initData();
			},

			//打开新增编辑，弹窗
			// openDioalog() {
			// 	//   // console.log(formParm);
			// 	// this.dialogFormVisible = true;
			// 	// this.editId = null;
			// 	// this.openTimeEditId = new Date().getTime();

			// 	this.$router.push({
			// 		name: "TransportFastEdit"
			// 	});
			// },
			// //打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	// this.openDioalog(row);
			// 	this.$router.push({
			// 		name: "TransportFastEdit",
			// 		query: {
			// 			editId: row.id,
			// 			single: true,
			// 		}
			// 	})

			// },

			//查看详情
			// showDetList($event, row) {
			// 	$event.stopPropagation();
			// 	// this.dialogShowDet = true;
			// 	// this.sendId = row.id;
			// 	// this.$router.push({
			// 	// 	name: "TransportInWhEdit",
			// 	// 	query: {
			// 	// 		editId: row.id
			// 	// 	}
			// 	// });
			// 	this.$router.push({
			// 		name: "TransportInWhDetList",
			// 		query: {
			// 			id: row.id
			// 		}
			// 	});

			// },

			//售后
			// openQARecList(event, row){
			// 	event.stopPropagation();
			// 	this.QAMoRecListRelationId = row.id;
			// 	this.QAMoRecListRelationNo = row.inWhCode;
			// 	// this.QAMoRecListUserId = row.userId;
			// 	this.QAMoRecListWhNo = row.whNo;
			// 	this.QAMoRecListOpenTime = new Date().getTime();
			// },
			// QAMoRecListSuccess() {
			// 	this.initData();
			// },

			// editSuccess() {
			// 	this.dialogFormVisible = false;
			// 	this.initData();
			// },
			//明细
			//请求分页数据
			// getPageDetData(sendId) {
			//   // let _this = this;
			//   this.loading_det = true;
			//   this.$http.put(this.$urlConfig.WhShipmentsListDetPageList, {
			//       // "sortAsc": this.filterData.sortAsc,
			//       // "orderBy": this.filterData.orderBy,
			//       "offset": 0, //当前页
			//       "limit": 100, //当前页显示数目

			//       sendId: sendId,

			//       // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
			//       // goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
			//       // hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
			//       // goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
			//       // declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
			//     })
			//     .then(({ data }) => {

			//     // console.log(this.$store.getters.getUserInfo);
			//       console.log("分页，请求成功");
			//       console.log(data);
			//       
			//       this.loading_det = false;
			//       //表格显示数据
			//       this.form.sendRecordDtoList = data.rows;
			//       //当前数据总条数
			//       // this.pagination.total = parseInt(data.total);
			//       //当前页数
			//       // this.pagination.current_page = parseInt(data.current);
			//       //当前页条数
			//       // this.pagination.page_size = parseInt(data.size);


			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("分页，请求失败");
			//       this.loading_det = false;
			//     });
			// },
			//出库
			// toOutAction(event, row) {
			// 	event.stopPropagation();

			// 	this.$router.push({name:"TransportInWhByToOut",query:{id:row.id}});
			// },
			//提交
			// commitAction(event, row) {
			// 	event.stopPropagation();
			// 	this.$confirm(this.$t('tips.confirmSubmit'), this.$t('tips.tipsTitle'), {
			// 			// confirmButtonText: this.$t('204ffab8a6e01870'),
			// 			// cancelButtonText: this.$t('4b3340f7872b411f'),
			// 			type: 'warning'
			// 		})
			// 		.then(() => {

			// 			this.postData(this.$urlConfig.WhTransferCommit, {
			// 				id: row.id
			// 			});
			// 		})
			// 		.catch(() => {});
			// },
			//删除
			delAction(event, row) {
				event.stopPropagation();
				this.$confirm(this.$t("FormMsg.confirm_Delete"), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('204ffab8a6e01870'),
						// cancelButtonText: this.$t('4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						// this.delDataAction(event, row);
						this.postData(this.$urlConfig.WhTransferFastDel + '/' + row.id, {}, 'delete');
					})
					.catch(() => {});
			},

			//撤销
			// commitCancelAction(event, row) {
			//   event.stopPropagation();

			//   let parm = [];

			//     if (row) {
			//   //     //单条
			//       parm = [row.id];
			//     } else {
			//       //多条
			//       let dataList = this.multipleSelection;
			//       if (dataList.length < 1) {
			//         this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
			//         return;
			//       }
			//       // if (dataList.findIndex(item=> '10'!=item.status)>-1) {
			//       //   this.$message.warning(this.$t('tips.long_InWarehouse_1'));
			//       //   return;
			//       // }

			//       let dataListParm = dataList.map(v => v.id);
			//       parm = dataListParm;
			//     }

			//   this.$confirm(this.$t('Storage.DropShipping.sure_submit_1')+parm.length+this.$t('Storage.DropShipping.sure_submit_2'), this.$t('tips.tipsTitle'), {
			//     // confirmButtonText: this.$t('204ffab8a6e01870'),
			//     // cancelButtonText: this.$t('4b3340f7872b411f'),
			//     type: 'warning'
			//   })
			//     .then(() => {
			// 			// {"ids":[11111111111111]}
			//       this.postData(this.$urlConfig.WhTransferRemove, {"ids":parm});
			//     })
			//     .catch(() => {});
			// },

			//删除
			// delDataAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('delDataAction', row);
			// 	this.postData(this.$urlConfig.WhTransferDel + '/' + row.id, {}, 'delete');
			// },

			//发货指令
			// shipmentsAction(event, row) {
			// 	event.stopPropagation();
			// 	this.$confirm(this.$t('Storage.invoice_list.submit_sure'), this.$t('tips.tipsTitle'), {
			// 			// confirmButtonText: this.$t('204ffab8a6e01870'),
			// 			// cancelButtonText: this.$t('4b3340f7872b411f'),
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhShipmentsListCommand + '/' + row.id, {}, () => {
			// 				this.$message.success(this.$t('tips.submitSuccess'));
			// 			});
			// 		})
			// 		.catch(() => {
			// 		});
			// },

			//下载提货单
			// downloadBillLading(event, row) {
			// 	event.stopPropagation();
			// },

			//打开选择数据
			// openSelData(event, row, index) {
			//   event.stopPropagation();

			//   this.dialogSelVisible = true;
			//   this.openRowData = row;
			//   this.openRowIndex = index;
			//   // this.$nextTick(() => {
			//   //   this.$refs.hyUpLoadImg1.openFile();
			//   // });
			// },
			// //选择数据后回调
			// selWhGoodsData(selRow) {
			//   this.dialogSelVisible = false;
			//   console.log('selRow', selRow);
			//   let row = this.openRowData;
			//   // row.putWhNo = selRow.putWhNo;
			//   // row.plPlaceNo = selRow.placeNo;
			//   row.goodsSku = selRow.goodsSku;
			//   row.sendAcount = 0;
			//   row.mySkuDto = selRow;

			//   // row.goodsImg = selRow.goodsImg;
			//   // row.goodsName = selRow.goodsName;
			//   // // row.goodsId = selRow.id;

			//   // // row.cusCode = selRow.cusCode;
			//   // row.goodsWeight = selRow.goodsWeight;
			//   // // row.weightUnit = selRow.weightUnit;
			//   // row.goodsLength = selRow.goodsLength;
			//   // row.goodsWidth = selRow.goodsWidth;
			//   // row.goodsHeight = selRow.goodsHeight;
			//   // row.sizeUnit = selRow.sizeUnit;

			//   // row.safeStock = selRow.safeStock;

			//   // // row.defCode = selRow.defCode;
			//   // // row.hashCode = selRow.hashCode;
			//   // // row.declareNameCh = selRow.declareNameCh;
			//   // // row.declareNameEn = selRow.declareNameEn;
			//   // row.goodsNameEn = selRow.goodsNameEn;
			//   // row.declarePrice = selRow.declarePrice;

			//   // defCode: '', //this.$t('i18nn_791e36963e37617a'),
			//   // hashCode: '', //this.$t('i18nn_198738759379a3c0'),
			//   // declareNameCh: '', //this.$t('i18nn_73d237ea940222ce'),
			//   // declareNameEn: '', //this.$t('i18nn_9894786aad05f849'),
			//   // declarePrice: '', //this.$t('i18nn_fe28507421c4c3b3'),

			//   this.$set(this.form.sendRecordDtoList, this.openRowIndex, row);

			//   //计算
			//   // this.CalculateTotalWeAndVo(row,this.openRowIndex);
			//   // this.openRowData = row;
			//   // this.openRowIndex = index;
			//   // this.$nextTick(() => {
			//   //   this.$refs.hyUpLoadImg1.openFile();
			//   // });
			// },

			// hyUpLoadImg1: function(childValue) {
			//   // childValue就是子组件传过来的值
			//   this.form.goodsImg = childValue;
			// },

			//查看图片
			// openBigImg(event, imgsrc) {
			//   event.stopPropagation();
			//   console.log('openBigImg', imgsrc);
			//   this.dialogImgVisible = true;
			//   this.imgUrlBigShow = imgsrc;
			// },

			//点击状态为非出账弹出窗
			// noConfirm(){
			//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('204ffab8a6e01870'),
			//       });
			// },
			//提交信息
			// submitForm(formName) {
			//   this.$refs[formName].validate(valid => {
			//     if (valid) {
			//       // let formData = Object.assign({}, this.form);
			//       let formData = JSON.parse(JSON.stringify(this.form));
			//       //置空不需要传的数据
			//       formData.sendRecordDtoList.forEach(item=>{
			//         item.whFc = null;
			//       });
			//       //浅拷贝、对象属性的合并
			//       if (0 === this.dialogFormStatus) {
			//         formData.id = null;
			//         formData.userId = this.UserInfo.id;

			//         this.postData(this.$urlConfig.WhShipmentsListUpdate, formData);
			//       } else {
			//         formData.userId = this.UserInfo.id;
			//         this.postData(this.$urlConfig.WhShipmentsListUpdate, formData);
			//       }
			//     } else {
			//       console.log('error submit!!');
			//       this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
			//         type: 'warning'
			//         //confirmButtonText: this.$t('204ffab8a6e01870'),
			//       });
			//       return false;
			//     }
			//   });
			// },
			//重置输入框
			// resetForm(formName) {
			//   console.log(formName);
			//   // console.log(this.$refs[formName]);

			//   this.$nextTick(() => {
			//     if (this.$refs[formName]) {
			//       this.$refs[formName].resetFields();
			//       if ('form' === formName) {
			//         this[formName]['sendRecordDtoList'] = [];
			//       }
			//     } else {
			//       console.log('this.$refs[formName]', this.$refs[formName]);
			//     }
			//   });
			// },

			//提交信息
			postData(url, formData, type) {
				// HttpTypelet _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						this.initData();
						if ("delete" == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								type: 'success'
							});
						}
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('204ffab8a6e01870'),
					});
				});
			},

			handleClick(tab, event) {
				console.log(tab, event);
				// this.form.sendRecordDtoList = [];
			},

			//打开导入弹窗
			// openExcelDialog() {
			//   this.dialogUploadVisible = true;
			//   // this.excelData = [];
			//   this.form.sendRecordDtoList = [];
			//   // this.excelFileName = "";
			//   // this.$refs.file.value = '';
			// },
			// fileChange(e) {
			//   // this.loading = true;
			//   console.log(e);

			//   console.log(this.$t('i18nn_f156a9536049f461'), this.$refs.file);
			//   // this.excelData = [];
			//   this.form.sendRecordDtoList = [];
			//   this.fileExcel = null,
			//   this.excelFileName = "";
			//   if (!this.$refs.file || !this.$refs.file.files) {
			//     this.$alert(this.$t('module.upload.Unknown_file'), this.$t('tips.tipsTitle'), {
			//       // //confirmButtonText: this.$t('204ffab8a6e01870'),
			//     });
			//     return;
			//   }
			//   if (this.$refs.file.files[0]) {
			//     // if (this.fileAccept.indexOf(this.$refs.file.files[0].type) > -1) {
			//     if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
			//       this.fileExcel = this.$refs.file.files[0];
			//       this.excelFileName = this.fileExcel.name;
			//       // console.log(this.$t('i18nn_f156a9536049f461'),this.$refs.file);
			//       // this.uploadExcel();
			//     } else {
			//       this.$alert(this.$t('module.upload.uploaded_over')+'10M', this.$t('tips.tipsTitle'), {});
			//     }
			//     // } else {
			//     //   this.$alert(this.$t(this.$t('i18nn_4dadf13ebce2167d')), this.$t('tips.tipsTitle'), {
			//     //   });
			//     // }
			//   } else {
			//     // this.$alert(this.$t('i18nn_c8e99e36cefd743a'), this.$t('tips.tipsTitle'), {
			//     // });
			//     console.log(this.$t('i18nn_c8e99e36cefd743a'), this.$refs.file);
			//   }
			// },
			//  openExcel() {
			// try{
			//   this.$refs.file.value = '';
			// }catch(e){
			//   console.log(e);
			//   //TODO handle the exception
			// }
			//    // this.$refs.file.value = '';
			//    // this.$refs.file.dispatchEvent(new MouseEvent('click'));
			//    var comment = this.$refs.file;
			//    if (document.all) {
			//      // For IE
			//      comment.click();
			//    } else if (document.createEvent) {
			//      // 在ff中要为a标签添加click事件，并且侦听该事件
			//      var ev = document.createEvent('MouseEvents');
			//      ev.initEvent('click', false, true);
			//      comment.dispatchEvent(ev);
			//    } else {
			//      // this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
			//      this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
			//        // //confirmButtonText: this.$t('204ffab8a6e01870'),
			//      });
			//    }
			//  },
			//  //导入excel
			//  uploadExcel() {
			//    if (!this.fileExcel) {
			//      this.$alert(this.$t('module.upload.Please_sel_file'), this.$t('tips.tipsTitle'), {});
			//      return;
			//    }

			//    this.loadingExcel = true;

			//    let file = this.fileExcel;
			//    var formdata = new FormData();
			//    formdata.append('file', file);

			//    this.$http
			//      .post(this.$urlConfig.WhShipmentsListExUpload, formdata, {
			//        headers: {
			//          'Content-Type': 'multipart/form-data'
			//        }
			//      })
			//      .then(({ data }) => {
			//        console.log(this.$t('i18nn_bc868e024b80d2e3'));
			//        console.log(data);
			//        this.loadingExcel = false;
			//        if (200 == data.code && data.rows) {
			//          // this.excelData = data.rows;

			//          this.form.sendRecordDtoList = data.rows;

			//          this.$forceUpdate();
			//          // if('1'==data.data.flag){
			//          //   this.excelData = data.data.data;
			//          // } else {
			//          //   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			//          //     type: 'warning',
			//          //     //confirmButtonText: this.$t('204ffab8a6e01870'),
			//          //   });
			//          // }
			//        } else {
			//          this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//            type: 'warning'
			//            //confirmButtonText: this.$t('204ffab8a6e01870'),
			//          });
			//        }
			//        // if (200 == data.code && data.data) {
			//        //   if('1'==data.data.flag){
			//        //     this.excelData = data.data.data;
			//        //   } else {
			//        //     this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			//        //       type: 'warning',
			//        //       //confirmButtonText: this.$t('204ffab8a6e01870'),
			//        //     });
			//        //   }

			//        // } else {
			//        //   this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//        //     type: 'warning',
			//        //     //confirmButtonText: this.$t('204ffab8a6e01870'),
			//        //   });
			//        // }
			//      })
			//      .catch(error => {
			//        console.log(error);
			//        console.log(this.$t('tips.submitError'));
			//        this.loadingExcel = false;
			//        this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			//          type: 'warning'
			//          //confirmButtonText: this.$t('204ffab8a6e01870'),
			//        });
			//      });
			//  },
			//提交-导入excel
			// submitExcelForm(){
			//   this.loadingExcel = true;
			//   this.$http.put(this.$urlConfig.WhPackingListExSubmit, this.excelData)
			//     .then(({ data }) => {
			//       console.log(this.$t('tips.submitSuccess'));
			//       console.log(data);
			//       this.loadingExcel = false;
			//       if (200 == data.code) {
			//         if(data.data){
			//           // this.excelData = data.data.data;
			//           this.dialogUploadVisible = false;
			//           this.excelData = [];
			//           this.excelFileName = "";

			//           this.initData();
			//           this.$alert(this.$t('73dd0d43f580bc68'), this.$t('tips.tipsTitle'), {
			//             type: 'success',
			//             //confirmButtonText: this.$t('204ffab8a6e01870'),
			//           });
			//         } else {
			//           this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			//             type: 'warning',
			//             //confirmButtonText: this.$t('204ffab8a6e01870'),
			//           });
			//         }

			//       } else {
			//         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//           type: 'warning',
			//           //confirmButtonText: this.$t('204ffab8a6e01870'),
			//         });
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log(this.$t("tips.submitError"));
			//       this.loadingExcel = false;
			//       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('204ffab8a6e01870'),
			//       });
			//     });
			// },

			//查询数据
			// serPageData() {
			//   this.pagination.current_page = 1;
			//   this.getPageData();
			// },
			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.status = key;
				this.initData();
			},

			//格式换待导出去数据
			formatterData(arr) {
				let newArr = [];
				arr.forEach(item => {
					if (item.plandtoList && item.plandtoList.length > 0) {
						// {
						// 	title: this.$t('i18nn_cc335ba8f4b2cc0d'),
						// 	key: 'planNo'
						// },
						// {
						// 	title: this.$t('i18nn_e1e2b21c7b4b951f'),
						// 	key: 'trWayName'
						// },
						// {
						// 	title: this.$t('i18nn_0bbce74881c893df'),
						// 	key: 'addrTypeName'
						// },
						// {
						// 	title: this.$t('i18nn_8758fd50c87d6c9c'),
						// 	key: 'address'
						// },
						// {
						// 	title: this.$t('i18nn_b7a786a287eb00e4'),
						// 	key: 'isPlanedName'
						// },
						// {
						// 	title: this.$t('i18nn_fa02547543cff0f7'),
						// 	key: 'isChangeLabelName'
						// },
						// {
						// 	title: this.$t('i18nn_fb6105f4b294a9d0'),
						// 	key: 'planDate'
						// },
						// {
						// 	title: this.$t('i18nn_886ea69af099215d'),
						// 	key: 'pallteCountDet'
						// },
						// {
						// 	title: this.$t('i18nn_08e5062e3affb676'),
						// 	key: 'labelCount'
						// },
						// {
						// 	title: 'ISA',
						// 	key: 'isa'
						// },
						// {
						// 	title: 'PRO',
						// 	key: 'pro'
						// },
						// {
						// 	title: this.$t('i18nn_6867f16a2ac5e825'),
						// 	key: 'trunckingPrice'
						// },
						item.plandtoList.forEach(item2 => {
							// let goodsLWH = '';
							// if(item2.mySkuDto){
							// 	goodsLWH = item2.mySkuDto.goodsINCLength+' * \n'+item2.mySkuDto.goodsINCWidth+' * \n'+item2.mySkuDto.goodsINCHeight;
							// }
							// 箱标 FBA PO 数量 重量 体积 
							if (item2.planList && item2.planList.length > 0) {
								item2.planList.forEach(item3 => {
									newArr.push(Object.assign({}, item, {
										planNo: item2.planNo,
										trWayName: item2.trWayName,
										addrTypeName: item2.addrTypeName,
										address: item2.address,
										isPlanedName: item2.isPlanedName,
										isChangeLabelName: item2.isChangeLabelName,
										planDate: item2.planDate,
										pallteCountDet: item2.pallteCount,
										labelCount: item2.labelCount,
										isa: item2.isa,
										pro: item2.pro,
										trunckingPrice: item2.trunckingPrice,

										ctnMark: item3.ctnMark,
										fbaNo: item3.fbaNo,
										fbaPre: item3.fbaPre,
										quantity: item3.quantity,
										totalWeight: item3.totalWeight,
										totalVol: item3.totalVol,
										// mergeLength: item.sendRecordDtoList.length?item.sendRecordDtoList.length:1;
									}));
								})

							}
						})
					} else {
						newArr.push(item);
					}
				});
				console.log('newArr', newArr);
				return newArr;
			},

			//分页的筛选项数据
			pageFilterData() {

				// let commitDateArr = this.filterData.commitDateArr;
				// let startCommitDate = '';
				// let endCommitDate = '';
				// if (commitDateArr && commitDateArr.length == 2) {
				// 	startCommitDate = commitDateArr[0];
				// 	endCommitDate = commitDateArr[1];
				// }

				// startCommitDate  endCommitDate

				let sortAsc = null;
				let orderBy = null;
				// orderBy: 'id_', //排序字段
				// sortAsc: 'N', //N降序，Y升序
				//已接收，部分转运，已转运
				if ('20' == this.filterData.status || '35' == this.filterData.status || '30' == this.filterData.status) {
					sortAsc = 'N';
					orderBy = 'receive_date';
				}

				return {
					"sortAsc": sortAsc,
					"orderBy": orderBy,
					// workNo: this.filterData.workNo ? this.filterData.workNo : null,
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					// userId: this.cusUserId ? this.cusUserId : null,
					status: this.filterData.status ? this.filterData.status : null,
					// packingNo: this.filterData.packingNo ? this.filterData.packingNo : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,

					// startCommitDate: startCommitDate ? startCommitDate : null,
					// endCommitDate: endCommitDate ? endCommitDate : null,

					// carTmsType: this.filterData.wh_car_tms_type ? this.filterData.wh_car_tms_type : null,
					// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				};
			},
			//请求分页数据
			getPageData() {

				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhTransferFastPageList, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			//新增
			// addLe2() {
			//   console.log('this.form', this.form);
			//   this.form.sendRecordDtoList.push({
			//     goodsSku: '', //this.$t('i18nn_31f887c1ada79a99'),
			//     sendAcount: null, //this.$t('b9d4d3f6183bf3c1'),
			//     toWhNo: '', //this.$t('0f859b9b45732564'),
			//     fbaNo:'',//fba编号
			//     mySkuDto: {
			//       // cusCode: '', //this.$t('i18nn_64fed2851f79d875'),
			//       goodsName: '', //this.$t('i18nn_b23004db5349dfd2'),
			//       goodsNameEn: '', //this.$t('i18nn_05141ac0139eaf01'),
			//       goodsImg: '', //this.$t('i18nn_877c5a0e44a0eb07'),
			//       // defCode: '', //this.$t('i18nn_791e36963e37617a'),
			//       // hashCode: '', //this.$t('i18nn_198738759379a3c0'),
			//       // declareNameCh: '', //this.$t('i18nn_73d237ea940222ce'),
			//       // declareNameEn: '', //this.$t('i18nn_9894786aad05f849'),
			//       // declarePrice: '', //this.$t('i18nn_fe28507421c4c3b3'),
			//       goodsWeight: '', //this.$t('96f1a4b17e75892d'),
			//       sizeUnit: '', //this.$t('i18nn_38bc71c0dc55904b'),
			//       // weightUnit: '', //this.$t('i18nn_8e01866868930d09'),
			//       goodsLength: '', //this.$t('i18nn_2cba96917484569a'),
			//       goodsWidth: '', //this.$t('i18nn_9e24e0e4745f4948'),
			//       goodsHeight: '', //this.$t('i18nn_c5fa0857bc0df2d6'),
			//       safeStock: '', //this.$t('004617dc47191cb8'),
			//       // sendAcount: null //this.$t('b9d4d3f6183bf3c1'),
			//       // "id":"",//"数据id"
			//       remark: ''
			//     }
			//   });
			//   this.$forceUpdate();
			// },
			// //删除
			// delLe2(index) {
			//   this.form.sendRecordDtoList.splice(index, 1);
			// },


			//打开附件上传
			openUploadFile(event, row) {
				event.stopPropagation();
				// this.isShowFileUpload = true;
				this.fileRelationId = row.id;
				this.FileUploadOpenTime = new Date().getTime();
			},
			//附件上传成功回调
			FileUploadSuccess(data) {
				this.initData();
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = row.id;
				// this.FileUploadOpenTime = new Date().getTime();
			},

			//BOL附件查看
			// openUploadFileBol(event, row){
			// 	event.stopPropagation();
			// 	// this.BolFileDataList = row.bolFiles;
			// 	this.BolFileRelationId = row.id;
			// 	this.BolFileUploadOpenTime = new Date().getTime();
			// },
			// BolFileUploadSuccess(data) {
			// 	this.initData();
			// 	// event.stopPropagation();
			// 	// this.isShowFileUpload = true;
			// 	// this.fileRelationId = row.id;
			// 	// this.FileUploadOpenTime = new Date().getTime();
			// },

			//POD附件
			// openUploadFilePod(event, row){
			// 	event.stopPropagation();
			// 	this.PodFileDataList = row.podFiles;
			// 	this.PodFileViewOpenTime = new Date().getTime();
			// },

			//查看收货清单
			openReceiptList(event, row) {
				event.stopPropagation();
				this.selReceiptListData = row;
				this.openTimeReceiptList = new Date().getTime();
			},

			//BOL打印
			printBolAction(event, row) {
				event.stopPropagation();
				let ids = [];
				if (row) {
					ids = [row.id];
				} else {
					if (this.multipleSelection.length <= 0) {
						this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
						return;
					}
					ids = this.multipleSelection.map(item => item.id).join(',');
					// this.$router.push({name:"whPriterTransportPlanOutList",ids:ids});
				}

				let routeData = this.$router.resolve({
					name: 'whPriterTransportPlanOutList',
					query: {
						ids: ids
					}
				});
				window.open(routeData.href, '_blank');

			},

			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_car_tms_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t("tips.submitError");
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t("tips.submitRequestError"));
			// 		});
			// }
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_car_tms_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t("tips.submitError");
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t("tips.submitRequestError"));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {

	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }

	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>